<template>
  <v-container fluid>
    <v-row v-if="!isEditable">
      <v-col>
        <v-alert color="#FEF4E4" dark :style="{ color: '#B98A38' }">
          This record was <b>automatically generated from</b> an online training
          course and <b>therefore cannot be edited</b>.
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text">
            General Information
          </v-card-title>
          <v-card-text class="mt-4">
            <v-row align="center">
              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="edited.name"
                  dense
                  counter
                  maxlength="200"
                  outlined
                  :disabled="!isEditable"
                  label="Name"
                  :rules="required"
                />
              </v-col>
              <v-col class="pt-0">
                {{ edited.originalFilename }}
              </v-col>
              <v-col align="right" class="pt-0" :key="refreshUploaderKey">
                <span
                  v-if="isEditable"
                  @click="$refs.uploader.click()"
                  :style="{
                    color: '#337AB7',
                    'text-decoration': 'underline',
                    cursor: 'pointer',
                  }"
                >
                  Upload a new file
                </span>
                <span
                  v-if="!isEditable"
                  :style="{
                    color: 'grey',
                    'text-decoration': 'underline',
                  }"
                >
                  Upload a new file
                </span>
                <input
                  type="file"
                  ref="uploader"
                  class="d-none"
                  accept="application/pdf"
                  @change="updateSelectedFile($event)"
                />
              </v-col>
              <v-col v-if="isMaxFileSizeAlertShowing" cols="12">
                <v-alert
                  @input="
                    isMaxFileSizeAlertShowing = false;
                    refreshUploaderKey = !refreshUploaderKey;
                  "
                  dismissible
                  dense
                  outlined
                  type="error"
                >
                  The maximum file size is 100MBs. Please upload a file that is
                  less than 100MBs.
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="edited.description"
                  counter
                  maxlength="1000"
                  outlined
                  :disabled="!isEditable"
                  hide-details
                  label="Description"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <CustomizedAutoComplete
                  :value="edited.trainingRecordGroupId"
                  :items="groups"
                  :hide-details="'auto'"
                  label="Folder"
                  :rules="required"
                  @change="edited.trainingRecordGroupId = $event"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="edited.certificateId"
                  dense
                  counter
                  :disabled="!isEditable"
                  maxlength="1000"
                  outlined
                  label="Certificate ID"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="edited.issuer"
                  dense
                  counter
                  :disabled="!isEditable"
                  maxlength="1000"
                  outlined
                  label="Issued By"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-menu
                  v-model="completedAtMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="edited.completedAt"
                      readonly
                      dense
                      outlined
                      clearable
                      :disabled="!isEditable"
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                      prepend-icon="mdi-calendar"
                      label="Date Completed"
                      @click:clear="fixNullCreatedAt"
                    />
                  </template>
                  <v-date-picker
                    v-model="edited.completedAt"
                    scrollable
                    no-title
                    @input="completedAtMenu = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-menu
                  v-model="expireAtMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="edited.expireAt"
                      readonly
                      dense
                      outlined
                      :disabled="!isEditable"
                      clearable
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                      prepend-icon="mdi-calendar"
                      label="Recertification Date"
                      @click:clear="fixNullExpireAt"
                    />
                  </template>
                  <v-date-picker
                    v-model="edited.expireAt"
                    scrollable
                    no-title
                    :min="
                      edited.completedAt == '' ? undefined : edited.completedAt
                    "
                    @input="expireAtMenu = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col>
                <PublishButtonGroup
                  :is-published="edited.isPublished"
                  @click="edited.isPublished = $event"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col align="right">
                <v-btn
                  class="msaBlue white--text"
                  :disabled="disableSave"
                  @click="saveDetails()"
                >
                  save
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-row v-if="!loading">
          <v-col cols="12">
            <TrainingRecordHistory
              :trainingRecordId="$store.getters.selectedTrainingRecord.id"
              :key="reloadHistory"
            />
          </v-col>
          <v-col>
            <TrainingRecordAssignments :trainingRecord="trainingRecord" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TrainingRecordHistory from '@/components/TrainingRecordLibrary/TrainingRecordHistory.vue';
import TrainingRecordAssignments from '@/components/TrainingRecordLibrary/TrainingRecordAssignments.vue';
import PublishButtonGroup from '@/components/PublishButtonGroup';
export default {
  name: 'TrainingRecordDetailsPage',
  components: {
    TrainingRecordHistory,
    TrainingRecordAssignments,
    PublishButtonGroup,
  },
  computed: {
    isEditable() {
      return (
        this.trainingRecord.courseId == '' ||
        this.trainingRecord.courseId == undefined
      );
    },
    disableSave() {
      return (
        (JSON.stringify(this.edited) == JSON.stringify(this.trainingRecord) &&
          this.file == null) ||
        this.edited.name.length == 0 ||
        !this.edited.trainingRecordGroupId
      );
    },
  },
  data() {
    return {
      completedAtMenu: false,
      edited: { name: '' },
      expireAtMenu: false,
      file: null,
      groups: [],
      isMaxFileSizeAlertShowing: false,
      loading: true,
      refreshUploaderKey: false,
      reloadHistory: 99,
      required: [(v) => !!v || 'This field is required'],
      trainingRecord: {},
    };
  },
  methods: {
    getDetails() {
      const params = {
        loaderText: 'Loading...',
        id: this.$store.getters.selectedTrainingRecord.id,
      };
      const url = 'get-training-record-details?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.trainingRecord = response.data.trainingRecord;
          this.trainingRecord.isEditable = this.isEditable;
          this.trainingRecord.completedAt =
            this.$options.filters.filterAsLocalDate(
              this.trainingRecord.completedAt,
            );
          this.trainingRecord.expireAt =
            this.$options.filters.filterAsLocalDate(
              this.trainingRecord.expireAt,
            );
          this.edited = { ...this.trainingRecord };
          this.groups = response.data.groups;
          this.loading = false;
        })
        .catch((error) => error);
    },
    async updateSelectedFile(event) {
      let file = event.target.files[0];
      if (!file) {
        return;
      }

      try {
        file = await this.$helpers.processFile(
          file,
          this.$constants.ACCEPTED_MIME_TYPES.PDF,
          true,
        );
      } catch (error) {
        if (error.message) {
          this.$root.showMessage(
            'Error',
            error.message,
            () => null,
            null,
            'ok',
          );
        }
        return;
      }

      this.file = file;
      this.isMaxFileSizeAlertShowing = false;

      this.edited.originalFilename = this.file.name;
    },
    replaceTrainingRecord() {
      const url = 'replace-training-record';
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      const params = new FormData();
      params.append('file', this.file);
      params.append('mimeType', this.file.type);
      params.append('originalFilename', this.file.name);
      params.append('id', this.edited.id);
      params.append('jwtToken', this.$store.getters.jwtToken);
      params.append('loaderText', 'Uploading...');

      return this.$axios
        .post(url, params, headers)
        .then(() => {
          this.reloadHistory++;
          this.trainingRecord.originalFilename = this.file.name;
          this.edited.originalFilename = this.file.name;
          this.file = null;
        })
        .catch((error) => error);
    },
    saveDetails() {
      if (this.file != null) {
        this.replaceTrainingRecord().then(() => {
          const edited = { ...this.edited };

          if (edited.completedAt != '') {
            edited.completedAt = this.$helpers.formatDateTimeToUTC(
              edited.completedAt,
            );
          }

          if (edited.expireAt != '') {
            edited.expireAt = this.$helpers.formatDateTimeToUTC(
              edited.expireAt,
            );
          }

          const params = {
            loaderText: 'Loading...',
            trainingRecord: edited,
            snackText: 'Successfully saved.',
          };
          const url = 'update-training-record-details?format=json';
          this.$axios
            .post(url, params)
            .then(() => {
              this.trainingRecord = { ...this.edited };
            })
            .catch((error) => error);
        });
      } else {
        const edited = { ...this.edited };

        if (edited.completedAt != '') {
          edited.completedAt = this.$helpers.formatDateTimeToUTC(
            edited.completedAt,
          );
        }

        if (edited.expireAt != '') {
          edited.expireAt = this.$helpers.formatDateTimeToUTC(edited.expireAt);
        }

        const params = {
          loaderText: 'Loading...',
          trainingRecord: edited,
          snackText: 'Successfully saved.',
        };
        const url = 'update-training-record-details?format=json';
        this.$axios
          .post(url, params)
          .then(() => {
            this.trainingRecord = { ...this.edited };
          })
          .catch((error) => error);
      }
    },
    fixNullCreatedAt() {
      setTimeout(() => {
        if (this.edited.completedAt == null) {
          this.edited.completedAt = '';
        }
      }, 50);
    },
    fixNullExpireAt() {
      setTimeout(() => {
        if (this.edited.expireAt == null) {
          this.edited.expireAt = '';
        }
      }, 50);
    },
  },
  mounted() {
    this.getDetails();
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
